import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import struct from "../../Assets/Projects/struct.png";
import bms from "../../Assets/Projects/bms.png";
import drcp from "../../Assets/Projects/drcp.png";
import dc from "../../Assets/Projects/dc.png";
import musclemapp from "../../Assets/Projects/musclemapp.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={drcp}
              isBlog={false}
              title="Disaster Response Coordination Platform"
              description="A web-based system designed for managing resources, requests, and offers in a rescue operation environment. Developed as part of the Programming & Systems on the Web course for the academic year 2023-2024, this project integrates database management, server-side operations, and interactive client-side ."
              ghLink="https://github.com/nickpotamianos/Web-Project"
              demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={musclemapp}
              isBlog={false}
              title="MuscleMapp"
              description="MuscleMapp is designed to address common challenges faced by gym-goers and fitness professionals, such as finding nearby gyms with specific amenities, managing memberships, and scheduling workouts. The platform offers an intuitive interface that integrates personal training programs, progress tracking, and gym membership management in one place."
              ghLink="https://github.com/nickpotamianos/Software-Development-Project-Muscle-Mapp"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bms}
              isBlog={false}
              title="BMS GUI for UoP Racing"
              description="The UoP Racing CAN Interface is a graphical user interface designed to monitor and log the status of a racing car’s battery management system (BMS). It is an integral tool for ensuring safety during charging of the car, providing real-time data acquisition from the CAN system via a Teensy microcontroller. Designed specifically for the UoP racing team. "
              ghLink="https://github.com/nickpotamianos/UOP-Racing-Charging-GUI"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={struct}
              isBlog={false}
              title="ECU Configuration Tool"
              description="Designed to make ECU configuration accessible to both technical and non-technical users, with the helping hand of PyQT6. It provides a streamlined approach to tuning vehicle systems by interacting directly with the ECU’s memory structure. This eliminates the need for manual coding or re-flashing, allowing users to quickly adjust system settings, save them for future use, and ensure that the ECU operates with optimal parameters for performance and reliability. Designed exclusively for the UoP Racing Team. Contact me for accessing the repository."
              ghLink="mailto:npotamianos@acm.org"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dc}
              isBlog={false}
              title="Digital Communications Lab Exercise"
              description="This project explores various aspects of digital telecommunications using MATLAB, focusing on the following key areas: Information theory: Estimation of pixel symbol probabilities from an image and calculation of entropy.
    Huffman Coding: Creation of a Huffman tree and analysis of code efficiency.
    Binary Symmetric Channel (BSC): Simulation of BSC and calculation of mutual information and channel capacity.
    DPCM: Implementation of Differential Pulse Code Modulation (DPCM) encoding and decoding.
    Grade received: 10/10"
              ghLink="https://github.com/nickpotamianos/Digital-Communications-Lab-Project"
             />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
