import React from "react";
import { Col, Row } from "react-bootstrap";
import { SiAcm } from "react-icons/si";
import { ReactComponent as FslIcon } from "../../Assets/fsl.svg"; // Importing the SVG as a React component

function MemberOf() {
    return (
        <Row style={{ justifyContent: "center", paddingBottom: "50px"}}>
            <Col xs={6} md={3} className="tech-icons" >
                <a href="https://www.acm.org/" target="_blank" rel="noopener noreferrer">
                    <SiAcm size={150} color={"white"} /> {/* Increase the size of ACM icon */}
                </a>
            </Col>
            <Col xs={6} md={3} className="tech-icons" style={{ textAlign: "center" }}>
                <a href="https://uopracing.com/" target="_blank" rel="noopener noreferrer">
                    <FslIcon style={{ width: '320px', height: '140px' }} /> {/* Adjust FSL icon size if needed */}
                </a>
            </Col>
        </Row>
    );
}

export default MemberOf;
