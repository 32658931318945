import React from "react";
import Card from "react-bootstrap/Card";
import { ImDatabase } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            I am <span className="purple">Nick Potamianos </span>
            from <span className="purple">Athens, Greece.</span>
            <br />
            I am currently working as a software engineer with the University of Patras Racing Team.
            <br />
            I am pursuing an Integrated MEng (IMEng) in Computer Engineering and Informatics at the University of Patras.
            <br />
            <br />
            Apart from coding, here are some other activities I enjoy:
          </p>
          <ul>
            <li className="about-activity">
              <ImDatabase /> Working out
            </li>
            <li className="about-activity">
              <ImDatabase /> Playing chess
            </li>
            <li className="about-activity">
              <ImDatabase /> Traveling
            </li>
          </ul>
        </blockquote>
      </Card.Body>

    </Card>
  );
}

export default AboutCard;
